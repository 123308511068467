import React from "react";
import { Dropdown } from "antd-mobile";
import { useHistory } from "react-router-dom";
import styles from "./assets/index.module.less";
import { categories } from  '../../games/games'
import { gameconfig } from "../../games/gameconfig";
// const dropdownItems = [
//   "Hot Games",
//   "Casual Games",
//   "Arcade Games",
//   "Puzzle Games",
//   "Sports Games",
//   "Action Games",
//   "Adventure Games",
//   "Shooting Games",
//   "Strategy Games",
//   "Best Games",
// ];

const Nav = (props) => {
  const history = useHistory();
  return (
    <div className={`${styles["group_1"]} flex-row`}>
      <span className={`${styles["text_1"]}`} onClick={() => {
        history.push("/home");
      }}>{gameconfig.name}</span>
      <Dropdown closeOnClickAway={false} arrowIcon={null}>
        <Dropdown.Item
          key="sorter"
          title={
            <img
              className={`${styles["image_2"]}`}
              src={require("./assets/img/nav_icon.png")}
              alt=""
            />
          }
        >
          <div className={`${styles["group_3"]} flex-col`}>
            {categories.map((item, index) => (
              <div
                className={`${styles["text"]}`}
                key={index}
                onClick={() => {
                  history.push(`/category/${item.name}`);
                  window.location.reload();
                }}
              >
                {item.name}
              </div>
            ))}
          </div>
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
};

export default Nav;
