import { gameconfig } from "./gameconfig";
import { games } from "./games";
export  var common={};
common.getRandomElements=function(arr, count) {
    const shuffled = arr.slice();
    shuffled.sort(() => 0.5 - Math.random());
    return shuffled.splice(0, count);
} 
common.getGames=function(){
    var myGame={};
    for (const key in gameconfig.categories) {
        var game = games.filter(item =>
            gameconfig.categories[key].indexOf(item.id) >= 0
        );
        myGame[key]=game;
    }
    return myGame;
}
 