import React, { Component } from "react";
import Nav from "../../components/nav";
import styles from "./assets/index.module.less";
import Footer from "../../components/footer";
import { games } from "../../games/games";


class Category extends Component {
  state = {};
  setValue(val) {}
  render() {
    const { category } = this.props.match.params;
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles["group_1"]} flex-col`}>
          <div className={`${styles["section_1"]} flex-col`}>
            <Nav />
            <div className={`${styles["text-wrapper_1"]} flex-col`}>
              <span className={`${styles["text_2"]}`}>advertisement</span>
            </div>
            <span className={`${styles["text_3"]}`}>{category}</span>
            <div className={`${styles["grid_1"]} flex-row`}>
              {games.filter(item => item.category === category).map((item) => {
                  return (
                    <div
                      className={`${styles["list-items_1-0"]} flex-col`}
                      key={item.id}
                      onClick={() => {
                        this.props.history.push(`/detail/${item.id}`);
                      }}
                    >
                      <img src={`/${item.icon}`} alt="" />
                    </div>
                  );
                })}
            </div>
            <div className={`${styles["text-wrapper_2"]} flex-col`}>
              <span className={`${styles["text_4"]}`}>advertisement</span>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
export default Category;
