import React, { Component } from 'react';

import styles from './assets/index.module.less';
import Nav from '../../components/nav';

import {privacy,about,contact} from "../../games/agreement"

const title={"contact":"Contact Us","about":"About Us","privacy":"Privacy Policy"}
class Agreement extends Component {
  state = {};
  setValue(val) {}
  render() {
    const { type } = this.props.match.params;
    const html = type === 'privacy' ? privacy : type === 'about' ? about : contact;
    return (
      <div className={`${styles.page} flex-col justify-between`}>
        <div className={`${styles['block_1']} flex-col`}>
          <Nav />
          <span className={`${styles['text_2']}`}>{title[type]}</span>
          <div className={`${styles['text-wrapper_1']} flex-col`}>
          <span className={`${styles['paragraph_1']}`} dangerouslySetInnerHTML={{__html: html}} />
          </div>
        </div>
      </div>
    );
  }
}
export default Agreement;
