import React, { Component } from "react";
import { FloatingBubble } from "antd-mobile";
import { games } from "../../games/games";
import styles from "./assets/index.module.less";

class Game extends Component {
  state = {};
  setValue(val) {}
  render() {
    const { name } = this.props.match.params;
    const game = games.find(item => item.name === name);
    return (
      <div className={`${styles.page} flex-col`}>
        <FloatingBubble
          axis="xy"
          magnetic="x"
          style={{
            "--initial-position-top": "24px",
            "--initial-position-left": "24px",
            "--edge-distance": "24px",
          }}
          onClick={() => {
            this.props.history.push("/");
          }}
        >
          <svg
            t="1728897738097"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1594"
            width="36"
            height="36"
          >
            <path
              d="M923.733333 394.666667c-85.333333-70.4-206.933333-174.933333-362.666666-309.333334C533.333333 61.866667 490.666667 61.866667 462.933333 85.333333c-155.733333 134.4-277.333333 238.933333-362.666666 309.333334-14.933333 14.933333-25.6 34.133333-25.6 53.333333 0 38.4 32 70.4 70.4 70.4H192v358.4c0 29.866667 23.466667 53.333333 53.333333 53.333333H405.333333c29.866667 0 53.333333-23.466667 53.333334-53.333333v-206.933333h106.666666v206.933333c0 29.866667 23.466667 53.333333 53.333334 53.333333h160c29.866667 0 53.333333-23.466667 53.333333-53.333333V518.4h46.933333c38.4 0 70.4-32 70.4-70.4 0-21.333333-10.666667-40.533333-25.6-53.333333z m-44.8 59.733333h-57.6c-29.866667 0-53.333333 23.466667-53.333333 53.333333v358.4h-138.666667V661.333333c0-29.866667-23.466667-53.333333-53.333333-53.333333h-128c-29.866667 0-53.333333 23.466667-53.333333 53.333333v206.933334H256V507.733333c0-29.866667-23.466667-53.333333-53.333333-53.333333H145.066667c-4.266667 0-6.4-2.133333-6.4-6.4 0-2.133333 2.133333-4.266667 2.133333-6.4 85.333333-70.4 206.933333-174.933333 362.666667-309.333333 4.266667-4.266667 10.666667-4.266667 14.933333 0 155.733333 134.4 277.333333 238.933333 362.666667 309.333333 2.133333 2.133333 2.133333 2.133333 2.133333 4.266667 2.133333 6.4-2.133333 8.533333-4.266667 8.533333z"
              fill="#666666"
              p-id="1595"
            ></path>
          </svg>
        </FloatingBubble>
        <iframe
          src={game.url}
          title={name}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    );
  }
}
export default Game;
