import React, { Component } from "react";
import Nav from "../../components/nav";
import LazyLoad from 'react-lazyload';

import styles from "./assets/index.module.less";
import Footer from "../../components/footer";
import { common } from "../../games/common";

class Home extends Component {
  state = {};
  setValue(val) {}
  render() {
    var myGames=common.getGames();
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles["section_1"]} flex-col`}>
          <div className={`${styles["box_1"]} flex-col`}>
            <Nav />
            <div className={`${styles["text-wrapper_1"]} flex-col`}>
              <span className={`${styles["text_2"]}`}>advertisement</span>
            </div>
            {Object.keys(myGames).map((homeCate) => {
              return (
                <div key={homeCate}>
                  <div
                    className={`${styles["text-wrapper_2"]} flex-row justify-between`}
                  >
                    <span className={`${styles["text_3"]}`}>
                      {homeCate}&nbsp;Games
                    </span>
                    <span
                      className={`${styles["text_4"]}`}
                      onClick={() => {
                        this.props.history.push(`/category/${homeCate}`);
                      }}
                    >
                      More
                    </span>
                  </div>
                  <div className={`${styles["group_2"]} flex-row`}>
                    {myGames[homeCate]
                      .filter((item) => item.category === homeCate)
                      // .slice(0, homeCate === 'Hot' ? 12 : 6)
                      .slice(0, 12)
                      .map((item) => {
                        return (
                          <LazyLoad height={100} throttle={200} key={item.id}>
                            <div
                              className={`${styles["box_2"]} flex-col`}
                              onClick={() => {
                                this.props.history.push(`/detail/${item.id}`);
                              }}
                            >
                              <img src={`/${item.icon}`} alt="" />
                            </div>
                          </LazyLoad>
                        );
                      })}
                  </div>
                </div>
              );
            })}
            <div className={`${styles["text-wrapper_12"]} flex-col`}>
              <span className={`${styles["text_23"]}`}>advertisement</span>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
