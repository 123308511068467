import React, { Component } from 'react'
import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import agreement from './view/agreement/index.jsx'
import detail from './view/detail/index.jsx'
import game from './view/game/index.jsx'
import category from './view/category/index.jsx'
import home from './view/home/index.jsx'
// import { CookieConsent } from 'react-cookie-consent';
import ScrollTop from './components/scrollTop/index.jsx'
import { gameconfig } from "./games/gameconfig";
class App extends Component {
  render() {
    document.title = gameconfig.name;
    return (
      <>
        {/* <CookieConsent
          buttonText="Accept"
          expires={1}
          cookieName="my_cookie_name"
          containerClasses="cookie-container"
          buttonClasses="cookie-button"
        >
          This website uses cookies to enhance your browsing experience. By clicking the 'Accept' button, you agree to our use of cookies.
        </CookieConsent> */}

        <Router>
          <ScrollTop>
            <div>
              <Switch>
                <Route exact path="/agreement/:type" component={agreement} />
                <Route exact path="/detail/:id" component={detail} />
                <Route exact path="/game/:name" component={game} />
                <Route exact path="/category/:category" component={category} />
                <Route exact path="/home" component={home} />
                <Redirect from="/" to="/home" />
              </Switch>
            </div>
          </ScrollTop>
        </Router>


      </>
    )
  }
}

export default App
