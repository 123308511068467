import React from "react";
import { useHistory } from "react-router-dom";
import { gameconfig } from "../../games/gameconfig";
import styles from "./assets/index.module.less";
const Footer = () => {
  const history = useHistory();
  return (
    <>
      <div className={`${styles["text-wrapper"]} flex-row`}>
        <span
          className={`${styles["text"]}`}
          onClick={() => {
             history.push("/agreement/contact");
          }}
        >
          Contact&nbsp;Us
        </span>
        <span
          className={`${styles["text"]}`}
          onClick={() => {
             history.push("/agreement/about");
          }}
        >
          About&nbsp;Us
        </span>
        <span
          className={`${styles["text"]}`}
          onClick={() => {
             history.push("/agreement/privacy");
          }}
        >
          Privacy&nbsp;Policy
        </span>
      </div>
      <span className={`${styles["text_bottom"]}`}>
        Copyright&nbsp;©&nbsp;2024&nbsp;{gameconfig.name}&nbsp;All&nbsp;Rights&nbsp;Reserved
      </span>
    </>
  );
};

export default Footer;
